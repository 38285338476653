$(function () {
  svg4everybody();
  AOS.init();

  var slider = $('.js-main-slider')
  slider.slick({
    prevArrow: slider.closest('.slider').find('.slick-nav__prev'),
    nextArrow: slider.closest('.slider').find('.slick-nav__next')
  });

  var partner = $('.js-partner-slider')
  partner.slick({
    prevArrow: partner.closest('.partner-block').find('.slick-nav__prev'),
    nextArrow: partner.closest('.partner-block').find('.slick-nav__next'),
    dots: true,
    slidesToScroll: 3,
    slidesToShow: 6,
    infinite: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
      },
      {
        breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });

  $('.js-hamb').on('click', function () {
    $(this).toggleClass('open');
    $('.menu').toggleClass('open');
  })

  $('.js-tabs-title a').on('click', function (e) {
    e.preventDefault();
    $('.js-tabs-title a').removeClass('active');
    $(this).addClass('active');
    var id = $(this).attr('href');
    $('.tab').removeClass('active');
    $(id).addClass('active');
  })

  $('.photo-video__link').on('click', function(e) {
    e.preventDefault();
    var gallery = $(this).closest('.js-photo-video').find('.photo-video__link:not(".slick-cloned")');
    //узнаём индекс слайда без учёта клонов
    var totalSlides = +$(this).parents('.slider').slick("getSlick").slideCount,
        dataIndex = +$(this).parents('.slide').data('slick-index'),
        trueIndex;
    switch(true){
      case (dataIndex<0):
        trueIndex = totalSlides+dataIndex; break;
      case (dataIndex>=totalSlides):
        trueIndex = dataIndex%totalSlides; break;
      default:
        trueIndex = dataIndex;
    }
    $.fancybox.open(gallery,{}, trueIndex);
    return false;
  });

  $('.js-photo-video').each(function () {
    $(this).slick({
      prevArrow: $(this).closest('.slider-mini').find('.slick-nav__prev'),
      nextArrow: $(this).closest('.slider-mini').find('.slick-nav__next'),
      dots: true,
      slidesToScroll: 4,
      slidesToShow: 4,
      infinite: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  })

});
